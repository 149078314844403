import { Component, Input } from '@angular/core';
import { CategoryName, OdxUpdateAffectedCategoryItem, OdxUpdateResolveResult } from 'app/app-model/odx-import/odx-update';
import { OdxImportService } from 'app/app-services/odx-import.service';

import { MessageService } from '../../services/message-service.service';

@Component({
  selector: 'app-import-preview',
  templateUrl: './import-preview.component.html',
  styleUrls: ['./import-preview.component.css']
})

export class ImportPreviewComponent {

  // eslint-disable-next-line @typescript-eslint/member-ordering
  btnDisabled = false;
  resolveResult = new OdxUpdateResolveResult();
  private _specificationVersionId: number;
  private _odxUpdateAffectedCategoryItem: OdxUpdateAffectedCategoryItem;

  public get specificationVersionId(): number {
    return this._specificationVersionId;
  }

  @Input()
  public set specificationVersionId(id: number) {
    this._specificationVersionId = id;
  }

  public get odxUpdateAffectedCategoryItem(): OdxUpdateAffectedCategoryItem {
    return this._odxUpdateAffectedCategoryItem;
  }

  @Input()
  public set odxUpdateAffectedCategoryItem(file: OdxUpdateAffectedCategoryItem) {
    this._odxUpdateAffectedCategoryItem = file;
  }

  constructor(private odxImportService: OdxImportService,
    private messageService: MessageService) { }

  parseEnum(idx: number) {
    return CategoryName[idx];
  }
}

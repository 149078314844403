import { Injectable } from '@angular/core';
import { OdxImport } from 'app/app-model/odx-import/odx-import';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

import { ApiProxyService } from '../modules/shared/services/api-proxy.service';
import { OdxUpdate, OdxUpdateAffectedCategoryItem } from 'app/app-model/odx-import/odx-update';

@Injectable({
    providedIn: 'root'
})
export class OdxImportService {

    constructor(private apiProxy: ApiProxyService) {
    }

    getOdxImports(specificationVersionId: number): Observable<OdxImport[]> {
        return this.apiProxy.get<OdxImport[]>(environment.apiUrl + 'odximports?specificationVersionId=' + specificationVersionId);
    }
}

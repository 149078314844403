/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum DiagnosticProtocolType {
  Kwp2000,
  Uds
}

export enum ServerExecutionModeType {
  Application,
  Bootloader
}

export enum DiagnosticServiceViewType {
  List,
  ProtocolServices,
  Identifiers
}

export enum DiagnosticServiceGroupType {
  ProtocolService,
  SubFunction,
  DataIdentifiers1Byte,
  DataIdentifiers2Byte,
  DataIdentifierType
}

export enum ProductAffinityType {
  Engines,
  Other
}

export enum CategoryNameType {
  Services,
  Servers,
  Identifications,
  Sessions,
  Fingerprints,
  IOs,
  IOLists,
  Values,
  EcuResets = 'Ecu Resets',
  OperationalDatas = 'Operational Datas',
  SecurityAccess = 'Security Access',
  SCOMMFiles = 'SCOMM Files',
  UnreferencedTestSteps = 'Unreferenced Test Steps',
  FreezeFrames = 'Freeze Frames',
  IvdOverride = 'Ivd Overrides',
  Vsoc = 'VSOC',
}

export enum CategoryName {
  CategoryItem = 0,
  OperationalDataVariable,
  Io,
  EcuIdentifier,
  Fingerprint,
  EcuValue,
  EcuReset,
  EcuSession,
  SecurityAccessMode,
  FreezeFrames,
  IvdOverride,
  VsocMonitoring
}

export enum CategoryIdType {
  services,
  servers,
  identifications,
  sessions,
  fingerprints,
  ios,
  iolists,
  values,
  files,
  freezeframes,
  ecuResets = 'ecu-resets',
  operational_datas = 'operational-datas',
  security_access = 'security-access',
  unreferenced_testSteps = 'test-steps',
  ivd_override = 'ivd-overrides',
  vsoc = 'vsoc',
}

export enum CommandType {
  Login,
  WriteFingerprint,
  EcuReset
}

export enum SpecificationType {
  Legacy = 0,
  OdxBased,
  Upgraded,
  Native
}

export enum OdxFileDialogStep {
  SelectFile,
  SignOdx,
  ConfirmSelection
}

export enum SocketConnectionState {
  Connecting,
  ConnectionFailed,
  Connected,
  Disconnected,
  Error
}

export enum LegacyContentType {
  UnreferencedTestStep,
  LegacyFreezeFrame
}

export enum IoType {
  Internal = 0,
  Pin,
  CAN
}
export enum ScaniaState {
  None = 0,
  TypeC,
  TypeD,
  GetTypeFromScaling
}
export enum FreezFramesResourceType {
  FreezeFrame
}

export enum FreezeFrameSource {
  IO = 0,
  Value,
  Internal
}

export enum FreezeFrameDataIndication {
  Range = 0,
  Rangeerror,
  Rangenotavailable,
  Value,
  Valueerror,
  Valuenotavailable,
  Rangevalue,
  Maxvalueerror
}

export enum FreezeFrameType {
  CommonRequired = 0,
  SystemAndDTCSpecific,
  Legacy
}

export enum FreezeFrameEnumType {
  Value,
  Interval
}

export enum ScalingDataType {
  unsigned = 0,
  signed,
  bitMappedWithoutMask,
  BCD,
  stateEncoded,
  ASCII,
  floatingPoint,
  packet,
  unsignedWithInd,
  ScaniaStateC,
  ScaniaStateD,

}

export enum DataType {
  Int32 = 0,
  UInt32,
  Float32,
  Float64,
  ByteField,
  AsciiString,
  Utf8String,
  UnicodeString,
  ScaniaStateC,
  ScaniaStateD
}

export enum ServiceIdentifier {
  Identifications = "0x22",
  Sessions = "0x10",
  SecurityAccess = "0x27",
  Fingerprints = "0x22",
  EcuResets = "0x11",
  IOs = '',  // no SID filter for Now
  Values = '', // no SID filter for Now
  OperationalDatas = "0x22",
  FreezeFrames = "0x22",
  Vsoc = '',
}
export enum RbacFileDialogStep {
  SelectFile,
  SignRbac,
  ConfirmSelection
}

export enum LinearFormulaType {
  Fixed,
  Customized
}

export enum EcuResetDelayType {
  DelayRequest,
  Delay
}

export enum ChangeType {
  AddConnection,
  RemoveConnection,
}

export enum RbaccEcuDiagnosticProtocol {
  Kwp2000,
  Uds,
  KwpAndUds,
}

export enum RbaccEcuDiagnosticSecurityType {
  SecurityGateway,
  SecureDiagnostic,
  SecurityGatewayAndSecureDiagnostic,
}


export enum RbaccState {
  InWork,
  UnderReview,
  Approved,
}


export enum RbaccEcuType {
  FlashableByScania,
  ParameterizationOnly

}

export enum PackageImportStatus {
  Success,
  Warning,
  Error
}

export enum ImportSourceType {
  Zip = 0,
  Csv = 1
}